<template>
    <div v-if="action === 'FreeVote'" 
            :class="['flex justify-between items-center gap-10 bg-tp-yellow text-tp-black font-bold text-xl p-3 rounded-xl', {'opacity-50 cursor-not-allowed': !availableFreeBoost}]" 
            @click="handleFreeVote">
        <p class="text-tp-black font-bold text-base">{{ availableFreeBoost ? '¡Usa tu voto gratis!' : 'Ya has usado tu voto gratis'}}</p>
        <div :class="['rounded-full w-6 h-6 text-tp-yellow text-sm bg-tp-black font-bold flex justify-center items-center', {'bg-gray-500': !availableFreeBoost}]">+</div>
    </div>
    <div v-if="action === 'Boost'" 
            class="flex justify-between items-center w-full bg-tp-yellow text-tp-black font-bold text-xl p-3 rounded-xl" 
            @click="emitBoost">
        <p class="text-tp-black font-bold text-base">¡Más votos!</p>
        <div class="rounded-full w-6 h-6 text-tp-yellow text-sm bg-tp-black font-bold flex justify-center items-center">
        <img :src="rocket" alt="" class="w-3">
        </div>
    </div>
</template>

<script>
import rocket from '@/assets/images/rocket-icon.png'
import { toRefs } from 'vue';

export default {
    name: 'RankingAction',
    props: {
        action: String,
        availableFreeBoost: Boolean
    },
    setup(props, { emit }) {
        const { availableFreeBoost } = toRefs(props);

        console.log('availableFreeBoost', availableFreeBoost)

        const emitBoost = () => {
            emit('onClickBoost');
        }

        const handleFreeVote = () => {
            if (availableFreeBoost.value) {
                emit('onClickFreeVote');
            }
        };

        return {
        rocket,
        emitBoost,
        handleFreeVote
        }
    }
}
</script>
  