<template>
  <div class="border-2 border-tp-light-green rounded-xl">
    <div class="bg-tp-yellow rounded-t-xl py-3 border-b-2 border-tp-light-green">
      <p class="text-tp-dark-green text-center font-bold">Mi perfil</p>
    </div>
    <div class=" flex flex-col gap-4 bg-tp-yellow rounded-b-xl px-8 py-6">
      <DJInput :icon=user label="Nombre" :model="djData.stage_name" />
      <!-- TODO :actionIcon=pencil-->
      <DJInput :icon=mail label="Correo" :model="djData.email" />
      <DJInput :icon=dollar label="Saldo actual" :model="djData.saldo" :actionIcon=withdraw />
      <!-- TODO :actionIcon=withDraw-->
    </div>
  </div>  
</template>
  
  <script>
  import DJInput from '@/components/DJ/DJInput.vue';
  import mail from '@/assets/images/mail.png';
  import pencil from '@/assets/images/pencil.png';
  import dollar from '@/assets/images/dollar.png';
  import withdraw from '@/assets/images/withdraw.png';
  import user from '@/assets/images/user-outline.png';

  export default {
    name: 'DJProfile',
    components: {
      DJInput
    },
    props: {
      djData: Object
    },
    setup() {
      
      function updateProfile() {
        alert('Perfil actualizado');
      }
      
      function withdrawMoney() {
        alert('Procesando retiro de dinero');
      }
      return {
        updateProfile,
        withdrawMoney,
        pencil,
        mail,
        dollar,
        user,
        withdraw
      }
    }
  }
  
  
  </script>
  