import createApiService from './apiService';

const apiService = createApiService();

async function getDJPrivateInfo() {
    try {
        const response = await apiService.get('/private/info');

        if (response.data && response.data.user_data){
            return response.data.user_data;

        }
        console.error("error with user data")
    
        return response.data;
    
      } catch (error) {
        console.error('Error getting DJInfo:', error);
        throw error;
      }
 
}

async function getSessionsInfo() {
    try {
        const response = await apiService.get('/private/sessions');
    
        return response.data;
    
      } catch (error) {
        console.error('Error getting Sessions info:', error);
        throw error;
      }
 
}

async function userLogin(email, password) {
    try {
        const url = `/private/login`;
        const formData = new FormData();
        
        formData.append('email', email);
        formData.append('password', password);

        const method = 'post';
        
        const response = await apiService.request({
          method,
          url,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        return response.data;
      } catch (error) {
        console.error('error during login', error);
        throw error;
      }
 
}

async function rejectSong(songId, loungeId) {
  try {
      const url = `/private/session/ranking/song/remove`;
      const formData = new FormData();
      
      formData.append('loungeID', loungeId);
      formData.append('songID', songId);

      const method = 'post';
      
      const response = await apiService.request({
        method,
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('error during song rejection', error);
      throw error;
    }

}

async function createRanking(rankingSongs, loungeId) {
  try {
      const url = `/private/session/ranking/create`;
      const formData = new FormData();
      
      formData.append('loungeID', loungeId);
      formData.append('song1', rankingSongs[0].songId);
      formData.append('song2', rankingSongs[1].songId);
      formData.append('song3', rankingSongs[2].songId);

      const method = 'post';
      
      const response = await apiService.request({
        method,
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('error during ranking creation', error);
      throw error;
    }

}

async function rankingCountdown(loungeId) {
  try {
      const url = `/private/session/ranking/countdown`;
      const formData = new FormData();
      
      formData.append('loungeID', loungeId);

      const method = 'post';
      
      const response = await apiService.request({
        method,
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('error during countdown', error);
      throw error;
    }

}

async function createLoungeSession(loungeId, maxDuration) {
  try {
      const url = `/private/session/create`;
      const formData = new FormData();
      
      formData.append('loungeID', loungeId);
      formData.append('max_duration', maxDuration);

      const method = 'post';
      
      const response = await apiService.request({
        method,
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('error during session creation', error);
      throw error;
    }

}

async function createIndependentSession(maxDuration) {
  try {
      const url = `/private/session/independent/create`;
      const formData = new FormData();
      
      formData.append('max_duration', maxDuration);

      const method = 'post';
      
      const response = await apiService.request({
        method,
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('error during session creation', error);
      throw error;
    }
}

async function closeSession(loungeId) {
  try {
      const url = `/private/session/close`;
      const formData = new FormData();
      
      formData.append('loungeID', loungeId);

      const method = 'post';
      
      const response = await apiService.request({
        method,
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('error during closing session', error);
      throw error;
    }
}

async function precreateVote(songs, loungeId) {
  try {
      const url = `/private/session/vote/precreate`;
      const formData = new FormData();
      
      formData.append('loungeID', loungeId);
      formData.append('song1', songs[0]);
      formData.append('song2', songs[1]);
      formData.append('song3', songs[2]);
      formData.append('song4', songs[3]);

      const method = 'post';
      
      const response = await apiService.request({
        method,
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('error during login', error);
      throw error;
    }
}


async function getPrecreateVote(loungeId) {
  try {
    const response = await apiService.get(`/private/session/${loungeId}/vote/precreate`);

    let data = response.data;
    if (data.error || (data.result && data.result.length === 0)) return null

    return data.result;
  
  } catch (error) {
    console.error('Error getting DJInfo:', error);
    throw error;
  }

}

async function launchPrecreateVote(loungeId, votingId) {
  try {
    const response = await apiService.post(`/private/session/${loungeId}/vote/precreate/${votingId}/launch`);

    let data = response.data;
    if (data.error || (data.result && data.result.length === 0)) return null

    return data.result;
  
  } catch (error) {
    console.error('Error launching general voting:', error);
    throw error;
  }

}

export { getDJPrivateInfo, getSessionsInfo, userLogin, rejectSong, createRanking, rankingCountdown, createLoungeSession, createIndependentSession, precreateVote, getPrecreateVote, launchPrecreateVote, closeSession };
