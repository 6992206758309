<template>
    <div class="modal-overlay" @click.self="handleCloseModal">
      <div class="relative">
        <div class="flex flex-col items-center justify-center border-4 border-tp-yellow bg-gradient-to-br from-tp-black to-tp-dark-green rounded-2xl p-7">
          <img class="absolute w-4 h-4 top-3 right-3 z-10" :src="closeIcon" alt="close-icon" @click="handleCloseModal">
          <p class="font-bold text-xl text-center mb-6">¿Estás seguro de que quieres votar por la canción: {{ songName }}?</p>
          <div class="flex w-full flex-col gap-4 pt-6">
            <button @click="emitFreeVote" class="w-full py-2 bg-green-500 text-white rounded-lg">Gastar voto gratis</button>
            <button @click="handleCloseModal" class="w-full py-2 bg-gray-500 text-white rounded-lg">Mejor más tarde</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import closeIcon from '@/assets/images/close-icon.png';
  
  export default {
    name: 'ConfirmGeneralVote',
    props: {
      songName: {
        type: String,
        required: true
      }
    },
    setup(props, { emit }) {
      const handleCloseModal = () => {
        emit('onCloseModal');
      };
  
      const emitFreeVote = () => {
        emit('onConfirmVote');
      };
  
      return {
        closeIcon,
        handleCloseModal,
        emitFreeVote
      };
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  </style>
  