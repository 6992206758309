<template>
  <div class="modal-overlay">
    <div class="relative">
      <div class="flex flex-col items-center justify-center border-4 border-tp-yellow bg-gradient-to-br from-tp-black to-tp-dark-green rounded-2xl p-7">
        <img class="absolute w-4 h-4 top-3 right-3 z-10" :src="close" alt="close-icon" @click="handleCloseModal">
        <p class="font-bold text-xl">¡Debería estar sonando esta canción!:</p>
        <div class="flex w-full flex-col gap-4 pt-6">
          <PodiumSong 
            :image="songToShow.image" 
            :artist="songToShow.artist" 
            :song="songToShow.songName" 
            :showBoostButton="false" 
            :position="1"  
            :class="'z-20'"
            :isDJ ="true"
          />          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import close from '@/assets/images/close-icon.png';
import info from '@/assets/images/info-icon.png';
import PodiumSong from '@/components/Ranking/PodiumSong.vue';

export default {
  name: 'DJShouldPlaySongModal',
  components: {
    PodiumSong
  },
  props: {
    songToShow: Object
  },
  setup(props, { emit }) {

    const handleCloseModal = () => {
      emit('onCloseModal');
    }
    return {
      close,
      info,
      handleCloseModal,
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
