<template>
    <div class="flex justify-between items-center bg-tp-yellow text-tp-dark-green border-2 border-tp-light-green px-4 py-2 rounded-xl gap-4">
        <p class="font-bold">Panel de control</p>
        <DJActionButton :image="arrow" :small="true" />
    </div>
</template>

<script>
import DJActionButton from './DJActionButton.vue';
import arrow from '@/assets/images/arrow-right.png';

export default {
  name: 'DJControlPanel',
  components: {
    DJActionButton
  },
  setup() {

    return {
        arrow
    };
  }
}

</script>