<template>
  <div class="modal-overlay" @click.self="handleCloseModal">
    <div class="relative">
      <div class="flex flex-col items-center justify-center border-4 border-tp-yellow bg-gradient-to-br from-tp-black to-tp-dark-green rounded-2xl p-7">
        <img class="absolute w-5 h-5 top-3 right-3 z-10" :src="close" alt="close-icon" @click="handleCloseModal">
        <p class="font-bold text-2xl">¿Cuántos?</p>
        <div class="flex flex-col gap-4 py-4">
          <VotePricing v-for="(votePlan, index) in votePlans" :key="index" :plan="votePlan.plan" :price="votePlan.price" :votes="votePlan.votes" @click="handleSubmitVotePlan(votePlan)"/>
          <Counter v-if="isAddSong" />
        </div>
        <div class="flex max-w-250px gap-2">
          <img class="w-5 h-5" :src="info" alt="">
          <p class="font-bold text-xs">Sólo se te cobrará el importe si tu canción es la ganadora.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import close from '@/assets/images/close-icon.png';
import info from '@/assets/images/info-icon.png';
import VotePricing from './VotePricing.vue';
import Counter from './Counter.vue';

export default {
  name: 'AddVotesModal',
  props: {
    isAddSong: Boolean,
    votePlans: Object
  },
  emits: ['onCloseModal', 'submitVotePlan'],
  components: {
    VotePricing,
    Counter
  },
  setup(props, { emit }) {
    const handleCloseModal = () => {
      emit('onCloseModal');
    };
    const handleSubmitVotePlan = (votePlan) => {
      emit('submitVotePlan', votePlan);
    }
    return {
      close,
      info,
      handleCloseModal,
      handleSubmitVotePlan
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>