<template>
  <div class="flex flex-col items-start text-tp-dark-green font-bold">
    <label class="text-sm" for="input">{{ label }}</label>
    <div class="flex w-full justify-between">
      <div class="flex items-center gap-2">
        <img :src=icon alt="" class="w-3.5 h-3.5">
        <input class="bg-tp-yellow text-sm focus:outline-none focus:ring-0" type="text" id="input" v-model="localModel" disabled>
      </div>
      <DJActionButton v-if ="actionIcon" :image=actionIcon />
    </div>
    <img class="mt-1" :src=line alt="">
  </div>
</template>
  
  <script>
  import line from '@/assets/images/green-line.png';
  import DJActionButton from '@/components/DJ/DJActionButton.vue';
  import { ref, watch, toRefs } from 'vue';

  export default {
    name: 'DJInput',
    components: {
      DJActionButton
    },
    props: {
      icon: String,
      label: String,
      model: String,
      actionIcon: String
    },
  setup(props) {
      const { model } = toRefs(props);
      const localModel = ref(model.value);

      // Watch for changes in the prop and update the local data property
      watch(model, (newValue) => {
        localModel.value = newValue;
      });

      return {
        line,
        localModel
      };
    }
  }
  
  </script>