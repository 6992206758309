<template>
  <NavBar/>
  <div class="flex flex-col items-center gap-2 w-full">
    <div v-if="generalSongs.length > 0" class="flex flex-col items-center gap-2">
      <img :src="trophy1" alt="" class="w-8">
      <VotingSong 
        v-if="generalSongs[0] && detailsLoaded"
        :image="generalSongs[0].image" 
        :artist="generalSongs[0].artist" 
        :song="generalSongs[0].songName" 
        :position="1" 
        :timerFinished="timerFinished"
      />
    </div>

    <div class="flex justify-center gap-5 w-full">
      <div v-if="generalSongs[2] && detailsLoaded" class="flex flex-col items-center gap-2 mt-10">
        <img :src="trophy3" alt="" class="w-6">
        <VotingSong 
          :image="generalSongs[2].image" 
          :artist="generalSongs[2].artist" 
          :song="generalSongs[2].songName" 
          :position="3" 
          :timerFinished="timerFinished"
        />
      </div>

      <div v-if="generalSongs[1] && detailsLoaded" class="flex flex-col items-center gap-2">
        <img :src="trophy2" alt="" class="w-6">
        <VotingSong           
          class="pb-10" 
          :image="generalSongs[1].image" 
          :artist="generalSongs[1].artist" 
          :song="generalSongs[1].songName" 
          :position="2" 
          :timerFinished="timerFinished"
        />
      </div>

      <div v-if="generalSongs[3] && detailsLoaded" class="flex flex-col items-center gap-2 mt-10">
        <img :src="trophy4" alt="" class="w-6">
        <VotingSong 
          :image="generalSongs[3].image" 
          :artist="generalSongs[3].artist" 
          :song="generalSongs[3].songName" 
          :position="4" 
          :timerFinished="timerFinished"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import trophy1 from '@/assets/images/trophy-1.png';
import trophy2 from '@/assets/images/trophy-2.png';
import trophy3 from '@/assets/images/trophy-3.png';
import trophy4 from '@/assets/images/trophy-4.png';
import VotingSong from '@/components/GeneralVoting/VotingSong.vue';
import { songsInfo } from '../service/musicService';
import NavBar from '@/components/Common/NavBar.vue';

export default {
  name: 'GeneralVoting',
  components: {
    VotingSong,
    NavBar
  },
  props: {
    loungeDetails: Object
  },
  setup(props) {
    const details = ref(null);
    const detailsLoaded = ref(false);
    const generalSongs = ref([]);
    const timerFinished = ref(true);
    const isRanking = ref(null);
    const songsData = ref(null);
    const firstSong = ref (null);

    onMounted(async () => {
      details.value =  props.loungeDetails;
      isRanking.value= props.loungeDetails.public.status === 'FINISHED_RANKING';
      if (details.value) {        
        if (isRanking.value){
          songsData.value = Object.values(details.value.public.ranking.songs);
          const songDetails = await songsInfo([details.value.public.lastRankingWinner]);
          firstSong.value =  songDetails[0];
        }
        else{
          songsData.value  = Object.values(details.value.public.votingData.songs);
        }
        const songIds = songsData.value.map(song => isRanking.value ? song.songInfo.songID : song.songID);    

        if (songIds.length > 0) {
          const songsResponse = await songsInfo(songIds);
          const orderedSongs = songIds.map(id => songsResponse.find(song => song.songId === id));
          generalSongs.value = orderedSongs;
        }
        if (firstSong.value) {
          generalSongs.value.unshift(firstSong.value); // agregar el firstSong al principio
        }
        detailsLoaded.value = true;
      }
    });

    return {
      trophy1,
      trophy2,
      trophy3,
      trophy4,
      generalSongs,
      timerFinished,
      isRanking,
      detailsLoaded
    };
  }
};
</script>
