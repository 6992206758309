<template>
    <div class="flex flex-col items-center py-16 gap-52 h-screen">
      <div class="flex flex-col items-center gap-6" :class="{'z-20': !isDJLoginVisible}">
        <img :src=logo alt="logo" class="w-20">
        <h2 class="text-tp-yellow text-5xl font-bold text-center">Dj<br>Tu fiesta<br>TuParty</h2>
      </div>
      <div class="flex flex-col items-center gap-6" :class="{'z-20': !isDJLoginVisible}">
        <button class="border-2 border-tp-yellow bg-tp-black text-tp-yellow font-bold text-lg px-20 py-2 rounded-full" @click="openLoginModal">Entra</button>
        <button class="bg-tp-yellow text-tp-black font-bold text-lg px-20 py-2 rounded-full">Regístrate</button>
      </div>
      <DJLoginModal :class="[{'transition-opacity duration-300 opacity-100 z-20': isDJLoginVisible}, {'opacity-0': !isDJLoginVisible}]" @onCloseModal="closeLoginModal"/>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import logo from '@/assets/images/logo.png'
  import DJLoginModal from '../../components/DJ/DJLoginModal.vue';
  
  export default {
    name: 'DJLogin',
    components: {
      DJLoginModal
    },
    setup() {
      const isDJLoginVisible = ref(false);

      const openLoginModal = () => {
        isDJLoginVisible.value = true;
      }

      const closeLoginModal = () => {
        isDJLoginVisible.value = false
      };

      return {
        logo,
        openLoginModal,
        closeLoginModal,
        isDJLoginVisible,
      }
    }
  }
  </script>
  