<template>
  <div class="relative">
    <NavBar />
    <DJActionButton class="absolute xxs:top-28 top-20 left-8" :yellowbg="true" :image="arrowBack" @click="handleBack"/>
    <div v-if ="detailsLoaded" class="flex flex-col gap-8 px-8 py-4">
      <p class="font-bold text-2xl">Sesiones Pasadas</p>
      <div v-for="session in pastSessionsInfo" :key="session.sessionID" class="bg-tp-yellow text-tp-dark-green p-4 rounded-xl">
        <div class="flex justify-between items-center">
          <div class="flex flex-col gap-1">
            <p><span class="font-bold">Fecha sesión: </span>{{ formatTimestamp(session.created_at) }}</p>
            <p><span class="font-bold">Dinero generado: </span>{{ session.dinero_generado}}€</p>
            <p><span class="font-bold">Lugar: </span>{{ session.loungeData.name}}</p>
          </div>
          <!-- <button @click="handleRedirect(session.sessionID)" class="flex h-10 bg-tp-dark-green text-tp-yellow font-bold px-4 rounded-lg items-center justify-center">Ver más info</button>-->
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import NavBar from '@/components/Common/NavBar.vue';
import DJActionButton from '@/components/DJ/DJActionButton.vue';
import arrowBack from '@/assets/images/arrow-back.png';
import { getSessionsInfo} from '../../service/djService';


export default {
  name: 'DJPastSessions',
  components: {
    NavBar,
    DJActionButton
  },
  setup() {
    const router = useRouter();

    const pastSessionsInfo = ref (null);

    const activeSession = ref(false);
    const isCreateSessionModalVisible = ref(false);
    const detailsLoaded = ref (false);

    const openCreateSessionModal = () => {
      isCreateSessionModalVisible.value = true;
    };

    const closeCreateSessionModal = () => {
      isCreateSessionModalVisible.value = false;
    };

    const handleRedirect = (sessionId) => {
      router.push(`/dj/past-sessions/session/${sessionId}`);
    };

    const handleBack = () => {
      router.push('/dj/private-area');
    };

    const formatTimestamp = (timestamp) => {
      const date = new Date(Math.floor(timestamp) * 1000);
      return date.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });

    }
    onMounted(async () => { 
        const sessionsInfo = await getSessionsInfo();
        console.log(sessionsInfo.past_sessions)
        pastSessionsInfo.value = sessionsInfo.past_sessions; 
        detailsLoaded.value= true;
      });

    return {
      activeSession,
      isCreateSessionModalVisible,
      openCreateSessionModal,
      closeCreateSessionModal,
      handleRedirect,
      pastSessionsInfo,
      arrowBack,
      handleBack,
      detailsLoaded,
      formatTimestamp
    };
  }
};
</script>
