<template>
  <div class="relative">
    <NavBar />
    <DJActionButton class="absolute xxs:top-26 top-20 left-8" :yellowbg="true" :image="arrowBack" @click="handleBack"/>
    <div class="flex flex-col gap-4 px-8 py-2">
      <p class="font-bold text-2xl">Votación</p>
      <Timer v-if = "detailsLoaded" :initialTime="timeLeft" />
      <p v-if="!noVoting && !isVotingFinished" class="font-bold">Deberás reproducir la canción que vaya primera cuando se acabe el contador y te avise</p>
      <p v-if="!noVoting && isVotingFinished" class="font-bold">¡Debería estar sonando la canción redondeada en rojo!</p>
      <p v-if="noVoting" class="font-bold">Ahora mismo no hay ninguna votación activa, sal afuera y crea otra si lo deseas.</p>
      <DJRankingSongCard v-for="(rankingSong, index) in votingSongs" :key="index" :rankingSong="rankingSong" :index="index+1" :isGeneralVoting = "true" :rankingFinished ="isVotingFinished"/>
    </div>
    <DJShouldPlaySongModal v-if="votingSongs && votingFinishedModal" :songToShow="votingSongs[0]" @onCloseModal="handleCloseModal"/>
  </div>
</template>

<script>
import DJRankingSongCard from '@/components/DJ/DJRankingSongCard.vue';
import arrowBack from '@/assets/images/arrow-back.png';
import Timer from '@/components/Common/Timer.vue';
import { ref, onMounted, onBeforeUnmount} from 'vue';
import { songsInfo } from '../../service/musicService';
import { useRouter, useRoute } from 'vue-router'
import DJActionButton from '../../components/DJ/DJActionButton.vue';
import NavBar from '@/components/Common/NavBar.vue';
import createLoungeService from '@/service/loungeService';
import DJShouldPlaySongModal from '../../components/DJ/DJShouldPlaySongModal.vue';


export default{
  name: 'DJActiveGeneralVoting',
  components: {
    Timer,
    DJRankingSongCard,
    DJActionButton,
    NavBar,
    DJShouldPlaySongModal
  },
  setup(){
    const router = useRouter();
    const route = useRoute();
    const loungeId = route.params.id;
    const loungeDetails = ref(null);
    const detailsLoaded = ref (false);
    const votingSongs = ref(null);
    const timeLeft = ref (null);
    const votingSongsWithoutVotes = ref(null);

    const votingFinishedModal = ref(false);
    const isVotingFinished = ref(false);
    const noVoting = ref(false);

    const pollingInterval= ref(null);


    const { fetchLoungeDetails } = createLoungeService();

    const handleBack = () => {
      router.go(-1);
    };

    const checkState = () => {
      if (loungeDetails.value.public && loungeDetails.value.public.status){
        if (loungeDetails.value.public.status === "GENERAL_VOTE"){
          isVotingFinished.value = false;
        }
        else if (loungeDetails.value.public.status === "FINISHED_GENERAL_VOTE"){
          if (isVotingFinished.value !== true){
            votingFinishedModal.value =true;
          }
          isVotingFinished.value = true;
        }
        else{
          noVoting.value = true;
        }
      }
    }


    const fetchDetails = async () => {
        if (!loungeId) return;
        const details = await fetchLoungeDetails(loungeId);
        if (details && details.public) {
          loungeDetails.value = details;
          refreshVotes();
          checkState();
          if(isVotingFinished.value === true){
            timeLeft.value = 180 + loungeDetails.value.public.votingData.timeLeft;
            console.log(loungeDetails.value)
          }
          else{
            timeLeft.value = loungeDetails.value.public.votingData.timeLeft;
          }
        }
    };
    const refreshVotes = () => {
      if (votingSongsWithoutVotes.value && loungeDetails.value && loungeDetails.value.public && loungeDetails.value.public.votingData) {
        const votesData = loungeDetails.value.public.votingData.songs;
        

        votingSongs.value = votingSongsWithoutVotes.value.map(song => {
          const voteInfo = Object.values(votesData).find(vote => vote.songID === song.songId);
          return {
            ...song,
            votes: voteInfo ? voteInfo.votos : 0,
            revenue: voteInfo ? voteInfo.revenue : 0
          };
        });
        votingSongs.value.sort((a, b) => b.votes - a.votes);
      }
    }

    onMounted(async () => {
      const details = await fetchLoungeDetails(loungeId);
      loungeDetails.value = details;
      if (loungeDetails.value && loungeDetails.value.public && loungeDetails.value.public.votingData) {
            const songsData = Object.values(loungeDetails.value.public.votingData.songs);
            console.log(songsData)
            const songIds = songsData.map(song => song.songID);
            votingSongsWithoutVotes.value = await songsInfo(songIds);
            timeLeft.value = loungeDetails.value.public.votingData.timeLeft;           
            detailsLoaded.value = true;
        }

      pollingInterval.value = setInterval(fetchDetails, 5000);
    });

    onBeforeUnmount(() => {
        clearInterval(pollingInterval.value);
        pollingInterval.value = null;
      });

    const handleCloseModal = () => {
        votingFinishedModal.value = false;
      };

    

    return{
      handleBack,
      timeLeft,
      votingSongs,
      detailsLoaded,
      arrowBack,
      votingFinishedModal,
      isVotingFinished,
      noVoting,
      handleCloseModal,
    }




  }
}

</script>
