import { ref } from 'vue';

export function useVotePlans() {
  const votePlans = ref([
    {
      plan: 'Basic Boost',
      price: 0.99,
      votes: 3
    },
    {
      plan: 'Normal Boost',
      price: 2.99,
      votes: 10
    },
    {
      plan: 'Pro Boost',
      price: 9.99,
      votes: 40
    }
  ]);

  return {
    votePlans
  };
}
