<template>
  <button :class="classes" class="relative flex justify-center items-center">
    <div v-if="isLoading" class="button-spinner"></div>
    <p v-else :class="textClasses">{{ text }}</p>
  </button>
</template>

<script>
export default {
  name: 'ButtonSpinner',
  props: {
    classes: String,
    textClasses: String,
    isLoading: Boolean,
    text: String
  }
};
</script>

<style>
.button-spinner {
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-left-color: #F9F871;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>