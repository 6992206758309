import { createStore } from 'vuex'

export default createStore({
  state: {
    loungeId: ''
  },
  mutations: {
    setLoungeId(state, id) {
      state.loungeId = id;
    }
  },
  actions: {
    updateLoungeId({ commit }, id) {
      commit('setLoungeId', id);
    }
  },
  getters: {
    getLoungeId: state => state.loungeId
  }
})
