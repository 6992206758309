<template>
  <div class="flex flex-col gap-4">
    <p class="font-bold text-xl">Sesiones pasadas</p>
    <p v-if="!pastSessions">No hay sesiones pasadas</p>
    <div v-else-if="pastSessions" class="flex justify-between items-center bg-tp-yellow text-tp-dark-green font-bold border-2 border-tp-light-green py-3 px-4 rounded-xl" @click="handleRedirection">
      <p>Número de sesiones pasadas: {{ pastSessionsInfo.length }}</p>
      <DJActionButton :image=arrow />
    </div>
  </div>
</template>
  
  <script>
  import arrow from '@/assets/images/arrow-right.png';
  import DJActionButton from './DJActionButton.vue';
  import { useRouter } from 'vue-router';

  export default {
    name: 'DJPastSessionsSection',
    components: {
      DJActionButton
    },
    props: {
      pastSessionsInfo: Object,
    },
    setup(props) {
      const router = useRouter();

      const pastSessions = props.pastSessionsInfo.length>0;

      const handleRedirection = () => {
        router.push('/dj/past-sessions');
      }


      return {
        arrow,
        pastSessions,
        handleRedirection
      };
    }
  }
  
  </script>