<template>
  <div class="flex justify-between bg-tp-yellow gap-8 p-3 rounded-xl" @click="handleSubmit">
    <div class="flex flex-col justify-between">
      <p class="text-tp-black font-bold text-xs">{{ plan }}</p>
      <p class="text-tp-black font-bold text-3xl font-sans">{{ price }}€</p>
    </div>
    <div class="flex items-center bg-tp-black rounded-md gap-2 px-2">
      <p class="text-tp-yellow font-semibold text-sm">+{{ votes }} votos</p>
      <img class="w-6 h-6" :src="rocket" alt="rocket">
    </div>
  </div>
  
</template>

<script>
import rocket from '@/assets/images/rocket-icon.png';
export default {
  name: 'VotePricing',
  props: {
    plan: String,
    price: Number,
    votes: Number
  },
  setup(props, { emit }) {
    const handleSubmit = () => {
      emit('onSubmit', props);
    };
    return {
      rocket,
      handleSubmit
    }
  }
};
</script>

<style>
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #F9F871;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>