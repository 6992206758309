<template>
    <div class="modal-overlay" @click.self="handleCloseModal">
        <div class="relative">
            <div class="flex flex-col gap-6 items-center justify-center border-4 border-tp-yellow bg-gradient-to-br from-tp-black to-tp-dark-green rounded-2xl py-8 px-10">
                <img class="absolute w-4 h-4 top-3 right-3 z-10" :src="close" alt="close-icon" @click="handleCloseModal">
                <p class="font-bold text-2xl">Crear nueva sesión</p>
                <p class="font-bold">¿Vas a usar tu QR de DJ o la sala en la que estás ya tiene TuParty?</p>
                <div class="flex flex-col bg-tp-yellow px-4 py-1 text-tp-black rounded-lg">
                    <div class="flex items-center justify-between gap-1">
                        <label class="text-sm" :class="{ 'font-bold': isIndependent }">Mi QR</label>
                        <div class="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                v-model="isIndependent" />
                            <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-tp-black cursor-pointer"></label>
                        </div>
                        <label class="text-sm" :class="{ 'font-bold': !isIndependent }">Sala TuParty</label>
                    </div>
                </div>
                <form v-if ="!isIndependent" class="flex flex-col gap-5" @submit.prevent="createSession">
                    <div class="flex flex-col bg-tp-yellow px-4 py-1 text-tp-black rounded-lg">
                        <div class="flex items-center gap-1">
                            <img :src=hashtag alt="" class="w-4 h-4">
                            <label class="text-sm" for="sessionId">Nº Sala</label>
                        </div>
                        <input class="bg-tp-yellow font-semibold focus:outline-none focus:ring-0" type="text" id="sessionId" v-model="sessionData.id" required>
                    </div>
            
                    <div class="flex flex-col bg-tp-yellow px-4 py-1 text-tp-black rounded-lg">
                        <div class="flex items-center gap-1">
                            <img :src=clock alt="" class="w-4 h-4">
                            <label class="text-sm" for="sessionId">Hora límite (formato 23:23)</label>
                        </div>
                        <input class="bg-tp-yellow font-semibold focus:outline-none focus:ring-0" type="time" id="sessionTime" v-model="sessionData.time" required>
                    </div>
            
                    <button class="bg-tp-black border-2 border-tp-yellow p-2 rounded-lg" type="submit">Crear Sesión</button>
                </form>
                <form v-if ="isIndependent" class="flex flex-col gap-5" @submit.prevent="createDJSession">           
                    <div class="flex flex-col bg-tp-yellow px-4 py-1 text-tp-black rounded-lg">
                        <div class="flex items-center gap-1">
                            <img :src=clock alt="" class="w-4 h-4">
                            <label class="text-sm" for="sessionId">Hora límite (formato 23:23)</label>
                        </div>
                        <input class="bg-tp-yellow font-semibold focus:outline-none focus:ring-0" type="time" id="sessionTime" v-model="sessionData.time" required>
                    </div>
            
                    <button class="bg-tp-black border-2 border-tp-yellow p-2 rounded-lg" type="submit">Crear Sesión</button>
                </form>
            </div>    
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import hashtag from '@/assets/images/hashtag.png';
    import clock from '@/assets/images/clock.png';
    import close from '@/assets/images/close-icon.png';
    import { createIndependentSession, createLoungeSession } from '../../service/djService';

    export default {
        name: 'DJCreateSessionModal',
        setup(props, { emit }) {
            const router = useRouter();
  
            const sessionData = ref({
                id: '',
                time: ''
            });

            const isIndependent = ref(true);
            const maxDuration = ref(null);
            

            const handleCloseModal = () => {
                emit('onCloseModal');
            }

            const createSession = async () => {
                const currentTime = new Date();

                const [hours, minutes] = sessionData.value.time.split(':').map(Number);

                const sessionTime = new Date();
                sessionTime.setHours(hours, minutes, 0, 0);

                const differenceInMs = sessionTime - currentTime;

                maxDuration.value = Math.floor(differenceInMs / 1000 / 60)+1;

                if (maxDuration.value<0){
                    maxDuration.value=maxDuration.value+1440
                }

                
                await createLoungeSession(sessionData.value.id, maxDuration.value);
                
                router.push('/dj/create-session');
            }

            const createDJSession = async () => {
                const currentTime = new Date();

                const [hours, minutes] = sessionData.value.time.split(':').map(Number);

                const sessionTime = new Date();
                sessionTime.setHours(hours, minutes, 0, 0);

                const differenceInMs = sessionTime - currentTime;

                maxDuration.value = Math.floor(differenceInMs / 1000 / 60)+1;

                if (maxDuration.value<0){
                    maxDuration.value=maxDuration.value+1440
                }
                await createIndependentSession(maxDuration.value);
                router.push('/dj/create-session');
            }


            return {
                sessionData,
                handleCloseModal,
                createSession,
                hashtag,
                clock,
                close,
                isIndependent,
                createDJSession
            }
        }
    }
</script>

<style scoped>
input[type="time"]::-webkit-calendar-picker-indicator { display: none; } 

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Contenedor del interruptor */
.toggle-checkbox {
    appearance: none;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Añadir posición relativa al label */
.toggle-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 42px;
    height: 22px;
    background-color: #ffcc00; /* Siempre amarillo */
    border-radius: 9999px;
    padding: 3px;
    position: relative;
    transition: background-color 0.3s ease-in-out;
}

/* El "punto" que se desliza */
.toggle-label:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #021211;
    transition: transform 0.3s ease-in-out;
}

/* El "punto" que se desliza */
.toggle-checkbox:checked + .toggle-label:before {
    transform: translateX(0); /* Cambiar a 0 cuando esté activado */
}

/* El "punto" cuando no está activado (TuParty) */
.toggle-checkbox:not(:checked) + .toggle-label:before {
    transform: translateX(20px); /* Desliza la bolita a la derecha */
}



</style>