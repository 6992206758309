<template>
    <div class="modal-overlay"> 
      <div class="relative"> 
        <img class="absolute w-4 h-4 top-3 right-3 z-10" :src="close" alt="close-icon" @click="emitCloseModal">
        <div class="flex flex-col items-center justify-center border-4 border-tp-yellow bg-gradient-to-br from-tp-black to-tp-dark-green rounded-2xl p-7 max-w-md mx-auto">
            <p class="text-tp-yellow font-bold text-3xl text-center mb-4">¿Estás seguro de que deseas cerrar la sesión?</p>
            <p class="text-tp-yellow font-bold text-center mb-4">** No podrás revertirlo, y se cancelarán todos los pagos no cobrados actualmente</p>
            <button 
                class="flex justify-between items-center gap-10 bg-tp-yellow text-tp-black font-bold text-xl p-3 rounded-xl mb-4" 
                @click="emitCloseSession">
                <p class="text-tp-black font-bold text-sm">Sí, cerrar sesión</p>
            </button>
            <button 
                class="inline-flex justify-center bg-tp-yellow text-tp-black font-bold text-xl px-6 py-3 rounded-xl" 
                @click="emitCloseModal">
                <p class="text-tp-black font-bold text-sm">Mejor no</p>
            </button>
        </div>
      </div> 
    </div> 
  </template>
  

<script>

import close from '@/assets/images/close-icon.png';

export default {
    name: 'DJCloseSessionModal',
    setup( props,{ emit }) {

        const emitCloseSession = () => {
            emit('onCloseSession'); 
        };

        const emitCloseModal = () => {
            emit ('onCloseModal');
        }

        return {
            close,
            emitCloseSession,
            emitCloseModal
        }
    }
}
</script>

<style scoped>
.modal-overlay { 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
  