<template>
  <div class="modal-overlay" @click.self="handleCloseModal">
    <div class="relative">
      <div class="flex flex-col items-center justify-center border-4 border-tp-yellow bg-gradient-to-br from-tp-black to-tp-dark-green rounded-2xl p-7 pt-10 gap-4">
        <img class="absolute w-4 h-4 top-3 right-3 z-10" :src="close" alt="close-icon" @click="handleCloseModal">
        <img class="w-26 h-26" :src="tick" alt="">
        <p class="font-bold text-xl">¡Has usado tu voto gratis!</p>
        <div class="flex justify-center w-full bg-tp-yellow text-tp-black font-bold text-xl p-3 rounded-xl" @click="handleCloseModal">
          <p class="text-tp-black font-bold text-sm">¡Continúa con TuParty!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import close from '@/assets/images/close-icon.png';
import tick from '@/assets/images/tick-icon-circle.png';

export default {
  name: 'ConfirmFreeVoteModal',
  setup(props, { emit }) {

    const handleCloseModal = () => {
      emit('onCloseModal');
    }

    return {
      close,
      tick,
      handleCloseModal,
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
