<template>
  <div class="flex flex-col items-start gap-4">
    <div class="flex gap-2 items-center">
      <p class="font-bold text-xl">4-hits</p>
      <img v-if="isActive" :src="live" alt="" class="w-5 h-5 animate-bounce">
    </div> 
    <div v-if="isActive" class="flex justify-between items-center bg-tp-yellow p-4 rounded-xl w-full">
      <div class="flex flex-col gap-1 text-tp-dark-green">
        <p v-for="(votingSong, index) in generalSongs" :key="index">
          <span class="font-bold">{{ index+1 }}.</span> {{ votingSong.songName }} - {{ votingSong.artist }}
          <span class="px-2 py-1 bg-tp-dark-green text-tp-yellow font-bold rounded-lg">{{ votingSong?.votes }}</span>
        </p>
      </div>
      <Timer v-if ="timeLeft" :initialTime="timeLeft" />
    </div>
    <DJControlPanel v-if="polls" class="cursor-pointer" @click="handleControlPanelRedirection" />
    <div v-if="!polls" class="flex flex-col justify-center gap-2">
      <p>No hay ningún 4-hits creado</p>
      <button class="bg-tp-yellow text-tp-dark-green font-bold border-2 border-tp-light-green py-2 px-6 rounded-xl w-max cursor-pointer" @click="handleCreateGeneralVoting">Crear 4-hits</button>
    </div>
  </div>
</template>
  
<script>
  import live from '@/assets/images/live.png';
  import DJControlPanel from './DJControlPanel.vue';
  import { useRouter } from 'vue-router';
  import { onMounted, watch, ref } from 'vue';
  import { songsInfo } from '../../service/musicService';
  import Timer from '@/components/Common/Timer.vue';

  export default {
    name: 'DJGeneralVotingSection',
    props: {
      publicData: Object,
      loungeId: String,
      polls: Object
    },
    components: {
      DJControlPanel,
      Timer
    },
    setup(props) {
      const router = useRouter();
      
      const generalSongs = ref(null);

      const timeLeft = ref (null);

      const isActive = ref (false);
      
      const handleControlPanelRedirection = () => {
        if (isActive.value === true){
          router.push(`/dj/active-session/${props.loungeId}/activeVoting`);
        }
        else{
          router.push(`/dj/active-session/${props.loungeId}/general-votings`)
        }     
      };

      const handleCreateGeneralVoting = () => {
        router.push(`/dj/active-session/${props.loungeId}/create-general-voting`)
      };

      const refreshVotes = (songsData, songsResponse) => {
        generalSongs.value = songsResponse.map(song => {
          const songData = songsData.find(s => s.songID === song.songId);
          return {
            songId: song.songId,
            image: song.image,
            songName: song.songName,
            artist: song.artist,
            votes: songData.votos
          };
        });
      };

      onMounted(async () => { 
        if (props.publicData && props.publicData.status) {
          if (props.publicData.status === "GENERAL_VOTE"){
            isActive.value = true;
            const songsData = Object.values(props.publicData.votingData.songs);
            const songIds = songsData.map(song => song.songID);
            if (songIds.length > 0) {
              const songsResponse = await songsInfo(songIds);
              refreshVotes(songsData, songsResponse);
            }
            timeLeft.value=props.publicData.votingData.timeLeft;
            }
          else{
            isActive.value = false;
          }
        }
      });

      watch(() => props.publicData, (newPublicData) => {
        const handleData = async () => {
          if (newPublicData) {
            if (props.publicData.status === "GENERAL_VOTE") {
              isActive.value = true;
              const songsData = Object.values(newPublicData.votingData.songs);
              const songIds = songsData.map(song => song.songID);

              if (songIds.length > 0) {
                if (!generalSongs.value){
                  generalSongs.value = await songsInfo(songIds);
                }
                refreshVotes(songsData, generalSongs.value);
              }
              timeLeft.value = newPublicData.votingData.timeLeft;
            } else {
              isActive.value = false;
            }
          }
        };

       handleData();   
      }, { deep: true });

      return {
        live,
        handleControlPanelRedirection,
        handleCreateGeneralVoting,
        generalSongs,
        isActive,
        timeLeft
      };
    }
  };
  
</script>