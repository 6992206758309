<template>
  <div :style="backgroundStyle" class="bg-cover bg-center h-screen flex flex-col items-center justify-end">
    <div class="relative">
      <input v-model="qrId" class="py-3 px-4 bg-tp-yellow placeholder-tp-placeholder-black placeholder-opacity-75 text-tp-black font-semibold rounded-2xl focus:outline-none mb-32" type="text" placeholder="Introduce ID QR">
      <button @click="processQRCode" class="absolute w-5 top-3 right-4">
        <img :src="tickIcon" alt="tickIcon" class="text-tp-black">
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import createLoungeService from '@/service/loungeService';
import { useRouter } from 'vue-router';

export default {
  name: 'Home',
  setup() {
    const backgroundImage = require('@/assets/images/home.png');
    const tickIcon = require('@/assets/images/tick-icon.png');
    const qrId = ref('');
    const backgroundStyle = ref({
      backgroundImage: `url(${backgroundImage})`
    });
    const { fetchLoungeIdFromQRId } = createLoungeService();
    const router = useRouter();

    const processQRCode = async () => {
      try {
        const loungeId = await fetchLoungeIdFromQRId(qrId.value);
        if (loungeId) {
          router.push(`lounge/${loungeId}`)
        }
      } catch (error) {
        console.error("Error processing QR Code:", error);
      }
    };

    return {
      backgroundImage,
      tickIcon,
      backgroundStyle,
      qrId,
      processQRCode
    };
  }
};
</script>
