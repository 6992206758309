<template>
  <div class="flex justify-center border-4 bg-tp-black rounded-xl py-1 px-2 my-2" :class="[{'border-tp-black': ranking}, {'border-tp-yellow': !ranking}]">
    <span class="text-tp-yellow font-medium text-2xl">{{ formattedTime }}</span>
  </div>
</template>

<script>
export default {
  name: 'Timer',
  props: {
    ranking: Boolean,
    initialTime: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      timeLeft: this.initialTime
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timeLeft / 60);
      const seconds = Math.floor(this.timeLeft % 60);
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      this.interval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft -= 1;
        } else {
          this.timeLeft = 0;
          clearInterval(this.interval);
        }
      }, 1000);
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
  }
};
</script>
