<template>
  <div class="h-full flex flex-col gap-2 justify-center items-center filter transition-all">
    <div class="relative" @click="emitBoost">
      <BoostButton v-if="showBoostButton" class="z-10 absolute -top-2 -right-3" :class="{'animate-bounce': showBoostButton}" :ranking="true" />
      <div :class="{'selected-shadow': mySong}">
        <img class="object-cover h-32 w-24 rounded-3xl overflow-hidden" :src="image">
      </div>
    </div>
    <div :class="[isDJ ? 'text-tp-yellow' : 'text-tp-black', 'text-center']">
      <p class="font-bold leading-none w-24 truncate text-xl">{{ song }}</p>
      <p class="font-bold w-24 truncate">{{ artist }}</p>
    </div>
  </div>
</template>

<script>
import BoostButton from '@/components/Common/BoostButton.vue';
export default {
  name: 'PodiumSong',
  components: {
    BoostButton
  },
  props: {
    mySong: Boolean,
    image: String,
    artist: String,
    song: String,
    showBoostButton: Boolean,
    position: Number,
    timerFinished: Boolean,
    isDJ: Boolean,
  },
  setup(props, { emit }) {
    const emitBoost = () => {
      emit('onBoostClick');
    };

    return {
      emitBoost,
    };
  }
};
</script>
<style>
.selected-shadow {
  filter: drop-shadow(1px 1px 10px #021211);
}
</style>
