<template>
  <div class="flex flex-col gap-4">
    <div class="flex gap-2 items-center">
      <p class="font-bold text-xl">Sesión activa</p>
      <img v-if="activeSession" :src=live alt="" class="w-5 h-5 animate-bounce">
    </div>
    <div v-if="!activeSession" class="flex flex-col justify-center gap-2">
      <p>No hay sesiones activas</p>
      <button class="bg-tp-yellow text-tp-dark-green font-bold border-2 border-tp-light-green py-2 px-3 rounded-xl w-max" @click="openModal">Crear sesión</button>
    </div>
    <div v-if ="activeSession" class="flex justify-between items-center bg-tp-yellow text-tp-dark-green border-2 border-tp-light-green py-3 px-4 rounded-xl" @click="handleRedirection">
      <div class="flex flex-col gap-1">
        <p><span class="font-bold">Estado actual:</span> {{ state}}</p>
        <p><span class="font-bold">Tiempo restante:</span> {{ timeLeft }} min</p>
      </div>
      <DJActionButton :image=arrow />
    </div>
  </div>

</template>
  
  <script>
  import {ref, onMounted} from 'vue';
  import live from '@/assets/images/live.png';
  import arrow from '@/assets/images/arrow-right.png';
  import DJActionButton from '@/components/DJ/DJActionButton.vue';
  import { useRouter } from 'vue-router';

  export default {
    name: 'DJActiveSessionSection',
    components: {
      DJActionButton
    },
    props: {
      activeInfo: Object,
      activeSession: Boolean,
    },
    setup(props, { emit }) {
      const router = useRouter();
      const state = ref (null);
      const timeLeft = ref(null);
  

      const openModal = () => {
        emit('onOpenModal')
      };

      const handleRedirection = () => {
        router.push(`/dj/active-session/${props.activeInfo.ID}`)
      }

      onMounted(() => {
        if (props.activeInfo) {
          if (props.activeInfo.public.status === "RANKING"){
            state.value = "RANKING";
            timeLeft.value = Math.floor(props.activeInfo.public.ranking.timeLeft / 60);
          }
          else if (props.activeInfo.public.status === "GENERAL_VOTE"){
            state.value = "4-hits"
            timeLeft.value = Math.floor(props.activeInfo.public.votingData.timeLeft / 60);
          }
          else if (props.activeInfo.public.status === "FINISHED_GENERAL_VOTE"){
            state.value = "Resultados 4-hits"
            timeLeft.value = 3+Math.floor(props.activeInfo.public.votingData.timeLeft / 60);
          }
          else if (props.activeInfo.public.status === "LOADING"){
            state.value = "Cargando.."
          }
        }        
      });

      return {
        live,
        arrow,
        openModal,
        handleRedirection,
        state,
        timeLeft
      };
    }
  }
  
  </script>