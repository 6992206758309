<template>
  <div class="relative flex justify-between gap-2 items-center w-full bg-tp-yellow rounded-xl p-3" >
    <div class="flex justify-between items-center gap-6">
      <div class="bg-tp-yellow rounded-full flex justify-center items-center">
        <img class="w-14 rounded-lg object-cover" :src="image" alt="">
      </div>
      <div class="flex flex-col font-extrabold leading-none text-tp-black">
        <p class="text-lg w-40 truncate">{{ song }}</p>
        <p class="opacity-60 w-40 truncate">{{ artist }}</p>
      </div>
    </div>
    <div v-if="payment" class="w-14 rounded-full bg-tp-black">
        <p class="font-bold text-center text-sm py-1 text-tp-yellow">{{ votes }}</p>
    </div>
    <div v-if="!payment" class="text-tp-yellow rounded-full w-6 h-6 bg-tp-black font-bold flex justify-center items-center">+</div>
  </div>
</template>

<script>

export default {
    name: 'SongCard',
    props: {
        song: String,
        artist: String,
        image: String,
        votes: Number,
        payment: {
          type: Boolean,
          default: false
        }
    },
    setup () {
    }
}
</script>