<template>
  <div class="flex flex-col gap-2 justify-center items-center filter transition-all border-4 border-tp-yellow rounded-3xl">
    <div class="relative" :class="{'selected-shadow': selected}">
      <BoostButton v-if="selected && showBoostButton" :class="[{'z-10 absolute -top-2 -right-3 transition-opacity duration-500 opacity-100 animate-bounce': selected}, {'opacity-0': !selected}]" :selected="selected" @click="emitBoost"/>
      <div class="relative rounded-t-3xl overflow-hidden">
        <img class="object-cover border-b-4 border-tp-yellow" :class="[{'h-44': (position == 1 && timerFinished) || !timerFinished}, {'h-24 w-20': [2, 3, 4].includes(position) && timerFinished}]" :src="image">
        <div v-if="progressPercentage >= 0" :style="{backgroundColor: darkOverlayColor, '--progress-percentage': progressPercentage + '%' }" class="progress-overlay">
          <span class="progress-text">{{ progressPercentage }}%</span>
        </div>
      </div>
    </div>
    <div :class="{'text-xs': [2, 3, 4].includes(position) && timerFinished}" class="flex flex-col w-full items-center text-center pb-2">
      <p class="font-bold" :class="[{'w-32 truncate': (position == 1 && timerFinished) || !timerFinished}, {'w-20 truncate': [2, 3, 4].includes(position) && timerFinished}]">{{ song }}</p>
      <p :class="[{'w-32 truncate': (position == 1 && timerFinished) || !timerFinished}, {'w-20 truncate': [2, 3, 4].includes(position) && timerFinished}]">{{ artist }}</p>
    </div>
  </div>
</template>

<script>
import BoostButton from '@/components/Common/BoostButton.vue';
export default {
  name: 'VotingSong',
  components: {
    BoostButton
  },
  props: {
    selected: Boolean,
    image: String,
    artist: String,
    song: String,
    progressPercentage: Number,
    darkOverlayColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.5)'
    },
    showBoostButton: Boolean,
    position: Number,
    timerFinished: Boolean
  },
  setup(props, { emit }) {
    console.log('progressPercentage: ', props.progressPercentage>=0);
    const emitBoost = () => {
      emit('onBoostClick');
    };

    return {
      emitBoost,
    };
  }
};
</script>

<style>
.selected-shadow {
  filter: drop-shadow(1px 1px 10px #f9f971);
}

.progress-overlay {
  position: absolute;
  width: var(--progress-percentage);
  top: 0;
  left: 0;
  bottom: 0;
  animation: slide 0.2s linear forwards;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-text {
  color: #F9F871;
  font-weight: 700;
  top: 50%;
  left: 50%;
  transform: rotate(-90deg) translate(50%, -50%);
  transform-origin: center;
  white-space: nowrap;
  position: absolute;
}

@keyframes slide {
  from {
    width: 0;
  }
  to {
    width: var(--progress-percentage);
  }
}
</style>
