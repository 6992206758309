<template>
  <div class="relative flex justify-between gap-2 items-center w-full max-h-72px bg-tp-yellow rounded-xl p-4" :class="{'bg-tp-black border-tp-yellow border-2 cursor-pointer': addSong || mySong}" @click="addSong ? handleAddSong() : emitBoost()">
    <div class="flex items-center gap-3">
      <p class="text-xl font-extrabold" :class="{'text-tp-black': !addSong && ! mySong}">{{ addSong ? '?' : position }}</p>
      <div class="bg-tp-yellow rounded-full w-10 h-10 flex justify-center items-center">
        <img :class="[{'w-10 h-10 rounded-full object-cover': !addSong}, {'w-6 h-6': addSong}]" :src="image" alt="">
      </div>
      <div class="flex flex-col font-extrabold leading-none" :class="{'text-tp-black': !addSong && !mySong}">
        <p class="text-lg w-28 truncate">{{ song }}</p>
        <p class="w-28 truncate" :class="{'opacity-60': !addSong && !mySong}">{{ artist }}</p>
      </div>
    </div>
    <div class="flex items-center gap-2">
      <div class="w-14 rounded-full" :class="[{'bg-tp-yellow': addSong || mySong}, {'bg-tp-black': !addSong && !mySong}]">
        <p class="font-bold text-center text-sm py-1" :class="{'text-tp-black': addSong || mySong}">{{ addSong ? '?' : votes }}</p>
      </div>
      <BoostButton v-if="!addSong && (mySong || songNotSelectedYet)" :ranking="true" :mySong="mySong"/>
    </div>
    <div v-if="addSong" class="absolute -top-2 -right-2 bg-tp-yellow rounded-full w-6 h-6 text-tp-black font-bold flex justify-center items-center">+</div>
  </div>
</template>

<script>
import BoostButton from '@/components/Common/BoostButton.vue'

export default {
  name: 'RankingSong',
  components: {
    BoostButton
  },
  props: {
    position: Number,
    image: String,
    song: String,
    artist: String,
    addSong: Boolean,
    votes: Number,
    mySong: Boolean,
    songNotSelectedYet: Boolean
  },
  setup(props, { emit }) {

    const emitBoost = () => {
      console.log('emitBoost')
      emit('onBoostClick');
    };

    const handleAddSong = () => {
      emit('addSong');
    };

    return {
      emitBoost,
      handleAddSong
    };
  }
};
</script>