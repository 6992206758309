<template>
    <div class="flex items-center justify-between bg-tp-yellow text-tp-black py-3 px-4 rounded-xl">
      <div class="flex items-center gap-3">
        <p class="font-bold">{{ index }}</p>
        <img :src="image" class="w-10 h-10 rounded-full" alt="">
        <div class="flex flex-col">
            <p class="font-bold text-lg w-40 xs:w-52 truncate">{{ name }}</p>
            <p class="font-bold text-sm opacity-60 w-40 xs:w-52 truncate">{{ artist }}</p>
        </div>
      </div>
      <div class="cursor-pointer text-tp-yellow rounded-full w-6 h-6 bg-tp-black font-bold flex justify-center items-center" @click="handleDeleteSong">-</div>
    </div>
</template>

<script>

export default {
  name: 'DJSelectedSong',
  props: {
    index: Number,
    name: String,
    artist: String,
    image: String,
  },
  setup (props, { emit }) {

    const handleDeleteSong = () => {
      console.log('Deleting song', props.index - 1)
      emit('deleteSong', props.index - 1)
    }

    return {
      handleDeleteSong
    }
  }
}
</script>