<template>
  <div class="modal-overlay" @click.self="handleCloseModal">
    <div class="relative">
      <div class="flex flex-col items-center justify-center border-4 border-tp-yellow bg-gradient-to-br from-tp-black to-tp-dark-green rounded-2xl p-7">
        <img class="absolute w-4 h-4 top-3 right-3 z-10" :src="close" alt="close-icon" @click="handleCloseModal">
        <p class="font-bold text-2xl">¡Añade votos!</p>
        <div class="flex w-full flex-col gap-4 pt-6">
          <RankingAction :action="'FreeVote'" :availableFreeBoost="availableFreeBoost" @onClickFreeVote="emitFreeVote"/>
          <RankingAction :action="'Boost'" @onClickBoost="emitBoost"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import close from '@/assets/images/close-icon.png';
import info from '@/assets/images/info-icon.png';
import RankingAction from './RankingAction.vue';
import { toRefs } from 'vue';

export default {
  name: 'RankingBoostModal',
  components: {
    RankingAction
  },
  props: {
    availableFreeBoost: Boolean
  },
  setup(props, { emit }) {
    const { availableFreeBoost } = toRefs(props);

    const handleCloseModal = () => {
      emit('onCloseModal');
    }

    const emitFreeVote = () => {
      console.log(availableFreeBoost);
      emit('onClickFreeVote');
    }

    const emitBoost = () => {
      emit('onClickBoost');
    }

    

    return {
      close,
      info,
      handleCloseModal,
      emitFreeVote,
      emitBoost
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
