<template>
  <button @click="handleAction" class="flex items-center justify-center rounded-full" :class="[{'bg-tp-yellow': yellowbg}, {'bg-tp-dark-green': !yellowbg}, {'p-1': small}, {'p-2': !small}]">
    <img class="w-3.5 h-3.5" :src=image alt="">
  </button>
</template>
  
  <script>

  export default {
    name: 'DJActionButton',
    props: {
      image: String,
      yellowbg: Boolean,
      small: Boolean
    },
    setup() {

      const handleAction = () => {
        console.log('Action button clicked');
      }
      
      return {
        handleAction
      }
    }
  }
  
  
  </script>
  