<template>
  <div class="relative">
    <NavBar />
    <DJActionButton class="absolute xxs:top-26 top-20 left-8" :yellowbg="true" :image="arrowBack" @click="handleBack"/>
    <div class="flex flex-col gap-4 px-8 py-2">
      <div class="flex flex-row justify-between gap-4 px-2 py-2">
        <p class="font-bold text-2xl">4-hits</p>
        <button class="bg-tp-yellow text-tp-dark-green font-bold border-2 border-tp-light-green py-2 px-6 rounded-xl w-max        cursor-pointer" @click="handleCreateGeneralVoting">Crear 4-hits</button>
      </div>
      
      <DJGeneralVotingCard v-for="(poll, index) in generalVotings" :key="index" :generalVoting="poll" 
        @launchGeneralVoting="launchGeneralVoting(poll)"/>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import NavBar from '@/components/Common/NavBar.vue';
import DJActionButton from '@/components/DJ/DJActionButton.vue';
import arrowBack from '@/assets/images/arrow-back.png';
import DJGeneralVotingCard from '@/components/DJ/DJGeneralVotingCard.vue';
import * as djService from '@/service/djService'
import { songsInfo } from '@/service/musicService';

export default {
  name: 'DJGeneralVotings',
  components: {
    NavBar,
    DJActionButton,
    DJGeneralVotingCard
  },
  setup() {
    const generalVotings = ref([]);
    const polls = ref(null);
    const router = useRouter();
    const route = useRoute();
    const loungeId = route.params.id;

    const handleBack = () => {
      router.go(-1);
    };

    // const initializeGeneralVotings = async () => {
      
    // }
    const initializeGeneralVotings = async () => {
      let songsIds = []
      polls.value.forEach(poll => {
        let songs = poll.songs
        console.log(polls.value)
        songsIds.push(songs.map(song => song.songID))
      });
      
      let newSongsResponse = null;

      if (songsIds.length > 0) {
        newSongsResponse = await songsInfo(songsIds);
      }
      if (!newSongsResponse) return;
      polls.value.map(poll => {
        console.log(poll.songs)
        poll.songs.map(song =>  {
          let songData = newSongsResponse.find(songResponse => songResponse.songId === song.songID);
          if (songData) {
            song.artist = songData.artist;
            song.title = songData.songName;
          }
        })
      });
      generalVotings.value = polls.value;
    };
 
    const launchGeneralVoting = async (poll) => {
      console.log(poll)
      let result = await djService.launchPrecreateVote(loungeId, poll.id)
      console.log(result)
    }

    const handleCreateGeneralVoting = () => {
      router.push(`/dj/active-session/${loungeId}/create-general-voting`)
    };

    onMounted(async () => {
      polls.value = await djService.getPrecreateVote(loungeId)
      initializeGeneralVotings();
    });

    return {
      arrowBack,
      handleBack,
      generalVotings,
      polls,
      launchGeneralVoting,
      handleCreateGeneralVoting
    };
  }
};
</script>
