<template>
    <div class="relative mx-8">
        <div class="flex justify-between items-center gap-2" :class="[{'opacity-0': isNavBarVisible}, {'opacity-100 transition-opacity duration-200': !isNavBarVisible}]">
            <img class="w-10"  :src="logo" alt="" @click="handleBack">
            <p class="font-bold text-lg">Añade <span v-if="isCreateSession || isCreateGeneralVoting">una</span><span v-if="!isCreateSession && !isCreateGeneralVoting">tu</span> canción</p>
            <img class="w-6 h-6 cursor-pointer" :src="lens" alt="" @click="toggleSearchBar">
        </div>
        <div v-if="isNavBarVisible" class="add-song-nav-bar" :class="{'opacity-100 bg-tp-yellow': isNavBarVisible}">
            <input v-model="inputValue" @input="handleInputChange" type="text" placeholder="Busca tu canción..." class="rounded-full w-full h-full font-semibold text-tp-black py-2 bg-tp-yellow focus:outline-none">
            <img class="w-6 h-6 cursor-pointer" :class="[{'opacity-0': !isNavBarVisible}, {'opacity-100 transition-opacity duration-200': isNavBarVisible}]" :src="close" style="filter: brightness(0%);" alt="" @click="toggleSearchBar">
        </div>
    </div>
</template>

<script>
import lens from '@/assets/images/lens-icon.png'
import darkLens from '@/assets/images/dark-lens-icon.png'
import close from '@/assets/images/close-icon.png';
import logo from '@/assets/images/logo.png'
import { useRouter} from 'vue-router'
import { ref, watch } from 'vue'

export default {
    name: 'AddSongNavBar',
    props: {
      isCreateSession: Boolean,
      isCreateGeneralVoting: Boolean,
      emptyInput: Boolean,
      selectedSongs: Array
    },
    setup(props, { emit }) {
        const router = useRouter();

        const inputValue = ref('');

        const isNavBarVisible = ref(false);

        const handleInputChange = (event) => {
          const value = event.target.value
          emit('search', value)
        }

        const toggleSearchBar = () => {
          isNavBarVisible.value = !isNavBarVisible.value
          if (isNavBarVisible.value === false) {
            inputValue.value = '';
            emit('close')
          }
        }
        const handleBack = () => {
          if (!props.isCreateSession || !props.isCreateGeneralVoting) {
            router.go(-1)
          }
          
        }

        watch(
          () => props.emptyInput,
          (newVal) => {
            if (newVal) {
              if (inputValue.value) {
                inputValue.value = '';
              }
              resetEmptyInput();
            }
          }
        );

        watch(
          () => props.selectedSongs,
          (newVal) => {
            if (props.isCreateSession && newVal && newVal.length === 3) {
              toggleSearchBar();
            } else if (props.isCreateGeneralVoting && newVal && newVal.length === 4) {
              toggleSearchBar();
            }
          },
          { deep: true }
        );

        const resetEmptyInput = () => {
          emit('resetEmptyInput');
        };

        return {
            lens,
            darkLens,
            close,
            logo,
            isNavBarVisible,
            toggleSearchBar,
            handleInputChange,
            handleBack,
            inputValue
        }
    }
}
</script>

<style>
.add-song-nav-bar {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: 0;
    opacity: 0;
    padding: 0 2rem;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    animation: slideNavBar 0.2s linear forwards;
}

@keyframes slideNavBar {
  from {
    width: 20%;
  }
  to {
    width: 100%;
  }
}

input::placeholder {
  color: #021211 !important;
  opacity: 0.5 !important;
}

input::-ms-input-placeholder {
  color: #021211 !important;
  opacity: 0.75 !important;
}
</style>